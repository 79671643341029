.search-container {
  position: relative;
  width: 100%;
  max-width: 600px;
}

/* System theme detection - Light */
@media (prefers-color-scheme: light) {
  .search-wrapper {
    background-color: #f3f4f6;
    border: 1px solid #e5e7eb;
  }

  .search-container.focused .search-wrapper {
    background-color: #ffffff;
    border-color: #d1d5db;
  }

  .search-icon {
    color: #6b7280;
  }

  .search-input {
    color: #1a1a1a;
  }

  .search-input::placeholder {
    color: #6b7280;
  }

  .voice-search-button {
    color: #6b7280;
  }

  .voice-search-button:hover {
    background: rgba(0, 0, 0, 0.05);
    color: #374151;
  }

  .mic-icon {
    color: #6b7280;
  }
}

/* System theme detection - Dark */
@media (prefers-color-scheme: dark) {
  .search-wrapper {
    background-color: #1E293B;
    border: 1px solid #ffffff1a;
  }

  .search-container.focused .search-wrapper {
    background-color: #1E293B;
    border-color: #ffffff33;
  }

  .search-icon {
    color: #64748B;
  }

  .search-input {
    color: #ffffff;
  }

  .search-input::placeholder {
    color: #64748B;
  }

  .voice-search-button {
    color: #64748B;
  }

  .voice-search-button:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #E2E8F0;
  }

  .mic-icon {
    color: #64748B;
  }
}

/* Common styles */
.search-wrapper {
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 2px 4px 2px 16px;
  height: 40px;
  transition: all 0.2s ease;
}

.search-icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.search-icon svg {
  width: 20px;
  height: 20px;
}

.search-input {
  width: 80%;
  height: 30px;
  padding: 8px 40px;
  border: none;
  background: transparent;
  font-size: 14px;
  transition: all 0.2s ease;
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 20px;
  padding-right: 40px;
  outline: none;
}

.voice-search-button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.mic-icon {
  width: 24px;
  height: 24px;
  color: #909090;
}

/* Additional styles for other components */

.create-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.create-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

.plus-icon {
  font-size: 18px;
  font-weight: bold;
}

/* Profile Button Styles */
.profile-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #1db954;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
}

.profile-button:hover {
  transform: scale(1.02);
} 