.app-header {
  z-index: 1000;
  padding: 8px;
  position: fixed;
  top: 0;
  left: 256px;
  right: 0;
  transition: all 0.3s ease;
}

/* System theme detection - Dark */
@media (prefers-color-scheme: dark) {
  .app-header {
    background-color: #1e1e2e;
    border-bottom: 1px solid #2d2d2d;
    color: #ffffff;
  }

  .header-content {
    color: #ffffff;
  }

  .notification-button {
    color: #ffffff;
  }

  .notification-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .profile-button {
    background: linear-gradient(135deg, #8b5cf6, #7c3aed);
  }

  .profile-dropdown {
    background-color: #1e1e2e;
    border: 1px solid #2d2d2d;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  }

  .dropdown-header {
    border-bottom: 1px solid #2d2d2d;
  }

  .profile-name {
    color: #ffffff;
  }

  .profile-email {
    color: #a7a7a7;
  }

  .dropdown-item {
    color: #ffffff;
  }

  .dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .dropdown-divider {
    background-color: #2d2d2d;
  }

  .create-button {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }

  .create-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .logo-text {
    color: #ffffff;
  }
}

/* System theme detection - Light */
@media (prefers-color-scheme: light) {
  .app-header {
    background-color: #ffffff;
    border-bottom: 1px solid #e5e7eb;
    color: #1a1a1a;
  }

  .header-content {
    color: #1a1a1a;
  }

  .notification-button {
    color: #1a1a1a;
  }

  .notification-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .profile-button {
    background: linear-gradient(135deg, #1db954, #169c46);
  }

  .profile-dropdown {
    background-color: #ffffff;
    border: 1px solid #e5e7eb;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  .dropdown-header {
    border-bottom: 1px solid #e5e7eb;
  }

  .profile-name {
    color: #1a1a1a;
  }

  .profile-email {
    color: #6b7280;
  }

  .dropdown-item {
    color: #1a1a1a;
  }

  .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .dropdown-divider {
    background-color: #e5e7eb;
  }

  .create-button {
    background-color: rgba(0, 0, 0, 0.05);
    color: #1a1a1a;
  }

  .create-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .logo-text {
    color: #374151; /* text-gray-700 */
  }
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  height: 56px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  max-width: 1800px;
  margin: 0 auto;
}

.header-left {
  flex: 1;
  max-width: 640px;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.create-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.plus-icon {
  font-size: 18px;
  font-weight: bold;
}

.notification-wrapper {
  position: relative;
}

.notification-button {
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.notification-badge {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #cc0000;
  color: white;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 10px;
  min-width: 16px;
  text-align: center;
}

.profile-button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #8b5cf6;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
}

.profile-button:hover {
  transform: scale(1.05);
}

.profile-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 320px;
  background: #1e293b;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  z-index: 1000;
}

.dropdown-header {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.profile-info {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.profile-avatar {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #8b5cf6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 16px;
  flex-shrink: 0;
}

.profile-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  min-width: 0;
}

.profile-name {
  color: white;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-email {
  color: #94a3b8;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-divider {
  height: 1px;
  background-color: #404040;
  margin: 4px 0;
}

.dropdown-menu {
  padding: 8px 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 12px 16px;
  border: none;
  background: none;
  color: white;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item i {
  font-size: 16px;
  width: 20px;
  text-align: center;
  opacity: 0.8;
}

.dropdown-item.logout {
  color: #ff4444;
}

.dropdown-item.logout i {
  color: #ff4444;
}

.logo span {
  color: white;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  max-width: 1800px;
  margin: 0 auto;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1;
}

.logo-section {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 200px;
}

.menu-button {
  background: transparent;
  border: none;
  color: white;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
}

.menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.logo {
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.create-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.create-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

.plus-icon {
  font-size: 18px;
  font-weight: bold;
}

.notification-wrapper {
  position: relative;
}

.notification-button {
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.notification-badge {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #cc0000;
  color: white;
  font-size: 11px;
  padding: 1px 4px;
  border-radius: 10px;
  min-width: 16px;
  text-align: center;
}

.profile-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #1db954;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
}

.profile-button:hover {
  background: #a29fa5;
  transform: scale(1.05);
  width: 36px;
  height: 36px;
}

.profile-initial {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
}

.dropdown-menu {
  padding: 8px 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;
  color: white;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: rgba(231, 213, 213, 0.05);
}

.dark .profile-name {
  color: white;
}

.dark .profile-email {
  color: #a7a7a7;
}

.dark .dropdown-divider {
  background-color: #2d2d2d;
}

.dropdown-item i {
  font-size: 18px;
  opacity: 0.8;
  width: 20px;
  text-align: center;
}

.dropdown-item.logout {
  color: #ef4444;
}

.dropdown-item.logout i {
  color: #ef4444;
}

@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .profile-dropdown {
    width: 280px;
    right: -8px;
  }
}

@media (max-width: 480px) {
  .profile-dropdown {
    width: calc(100vw - 32px);
    right: -16px;
  }
}

.logo-text {
  font-size: 1.125rem; /* text-lg */
  font-weight: 700; /* font-bold */
}
