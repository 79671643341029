/* Add this new file for Bell icon styles */
@media (prefers-color-scheme: light) {
  .notification-icon {
    color: #1a1a1a;
  }
}

@media (prefers-color-scheme: dark) {
  .notification-icon {
    color: #ffffff;
  }
} 